import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`My name is Bjørn and I'm a codeaholic!`}</h3>
    <br />
    <p>
  People call me a founder and an entrepreneur. I am not. I am just a guy who
  likes to code and make stuff. I am a true codeaholic.
    </p>
    <br />
    <ul>
      <li parentName="ul">{`I code because it makes me feel happy.`}</li>
      <li parentName="ul">{`I code to experience pleasure and satisfaction.`}</li>
      <li parentName="ul">{`I code because it makes me relax.`}</li>
      <li parentName="ul">{`I code to enjoy myself.`}</li>
      <li parentName="ul">{`I code to reward myself.`}</li>
      <li parentName="ul">{`I code to escape from reality.`}</li>
      <li parentName="ul">{`I code to cope with stress or problems.`}</li>
      <li parentName="ul">{`I code to alleviate anxiety.`}</li>
    </ul>
    <br />
    <p>{`All this coding sometimes results in useful and often good products.`}</p>
    <br />
    <p>{`But....`}</p>
    <br />
    <ul>
      <li parentName="ul">{`I choose to code instead of trying to sell my stuff.`}</li>
      <li parentName="ul">{`I choose to code instead of meeting potential customers.`}</li>
      <li parentName="ul">{`I choose to code instead of trying to find potential investors.`}</li>
      <li parentName="ul">{`I choose to code instead of doing marketing.`}</li>
      <li parentName="ul">{`I choose to code instead of sending out invoices.`}</li>
      <li parentName="ul">{`I choose to code long into the night even though I know it hurts my sleep.`}</li>
      <li parentName="ul">{`I choose to code instead of hanging with friends.`}</li>
    </ul>
    <br />
    <p>{`And...`}</p>
    <br />
    <ul>
      <li parentName="ul">{`I lie about how much I code.`}</li>
      <li parentName="ul">{`I lie about how often I code.`}</li>
      <li parentName="ul">{`I mostly code alone.`}</li>
      <li parentName="ul">{`I code a lot in the morning before breakfast.`}</li>
      <li parentName="ul">{`I neglect my responsibilities and keep on coding.`}</li>
      <li parentName="ul">{`I have blackouts and forget the world around me while coding.`}</li>
      <li parentName="ul">{`I become irritable if I am not able to code for a while.`}</li>
      <li parentName="ul">{`I think about coding all day, every day.`}</li>
      <li parentName="ul">{`I ignore my health and prioritise coding.`}</li>
      <li parentName="ul">{`I have coding benders that lasts for days.`}</li>
    </ul>
    <br />
    <p>{`Can anyone help me? Seriously.`}</p>
    <br />
    <div className="flex flex-wrap justify-center">
  <Image src="coding/codeaholics.jpg" mdxType="Image" />
  <div className="italic text-gray-700 text-sm">
    A fully booked meeting of introverted codeaholics anonymous
  </div>
    </div>
    <br />
    <p><a parentName="p" {...{
        "href": "/coding"
      }}>{`Sinnakoder'n`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      